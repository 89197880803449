import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const Image = styled(BackgroundImage)`
  width: 100%;
  padding-top: 56.25%;
  border: 5px solid ${props => props.theme.colors.imageBorder.grey};
  margin-bottom: 20px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding-top: 0;
    height: 375px;
  }
`

export default Image
