import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: 32px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
  }

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 30px;
  }
`

export default Wrapper
