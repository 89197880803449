import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.text.darkGrey};
  transition: transform 0.3s ease-in-out;

  &:hover {
    filter: none;
    transform: scale(1.01);
  }
`

export default Link
