import styled, { css } from "styled-components"

const Title = styled.div`
  background-color: rgba(152, 214, 205, 0.7);
  font-size: 18px;
  padding: 8px 10px;
  font-weight: 300;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    ${props =>
      props.big &&
      css`
        font-size: 36px;
        padding: 16px;
      `}
  }
`

export default Title
